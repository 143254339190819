import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import ReactGA from 'react-ga';
import {
  Grid,
  CssBaseline,
  Typography,
  Link,
  Button,
  Grow
} from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { blue, red, purple, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import logo from './images/logo.png';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh'
  },
  logo: {
    '& img': {
      maxWidth: theme.spacing(33)
    },
    textAlign: 'center',
    height: theme.spacing(33)
  },
  mainContainer: {
    backgroundColor: purple[500]
  },
  agreementsContainer: {
    backgroundColor: purple[500],
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  aboutContainer: {
    backgroundColor: blue[900]
  },
  connectionContainer: {
    backgroundColor: yellow[900]
  },
  joinContainer: {
    backgroundColor: purple[900]
  },
  contactContainer: {
    backgroundColor: red[500]
  },
  title: {
    padding: theme.spacing(1),
    letterSpacing: theme.spacing(1)
  },
  role: {
    padding: theme.spacing(1),
    letterSpacing: theme.spacing(1) / 2
  },
  verticallyAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  bodyText: {
    fontSize: theme.spacing(2.2)
  }
}));

export default function App() {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.initialize('UA-38230161-2');
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <Grid
        container
        className={clsx(classes.mainContainer, classes.container)}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <div className={classes.logo}>
            <Grow in style={{ transformOrigin: '0 0 0' }} timeout={8000}>
              <img src={logo} alt="Polyamory World" />
            </Grow>
          </div>
          <Typography
            variant="h3"
            component="h1"
            className={classes.title}
            gutterBottom
            align="center"
          >
            Polyamory World
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.role}
            align="center"
          >
            intimate consensual relationships with more than one partner
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.aboutContainer, classes.container)}
        justify="center"
        alignItems="center"
      >
        <Grid item className={classes.verticallyAlign} xs={8} md={5} lg={4}>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.bodyText}
            align="center"
          >
            Polyamory is the practice of, or desire for, intimate relationships
            with more than one partner, with the informed consent of all
            partners involved. It has been described as "consensual, ethical,
            and responsible non-monogamy".
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.bodyText}
            align="center"
          >
            —{' '}
            <Link href="https://en.wikipedia.org/wiki/Polyamory">
              Wikipedia
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.container, classes.connectionContainer)}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={9} sm={8} lg={6}>
          <Typography variant="h3" gutterBottom>
            How we connect
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.bodyText}>
            To really learn about a topic, we've learnt it's important to
            connect as close as possible to an in-person experience as we can
            digitally. So we use Keybase for all our typed conversations, and
            Multiverse to organise all our video chats.
          </Typography>
          <Button
            color="primary"
            size="large"
            startIcon={<LinkIcon />}
            href="https://keybase.io/"
          >
            Keybase
          </Button>
          <Button
            color="primary"
            size="large"
            startIcon={<LinkIcon />}
            href="https://multiverse.createdeepconnections.com/"
          >
            Multiverse
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.container, classes.joinContainer)}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={9} sm={8} lg={6}>
          <Typography variant="h3" gutterBottom>
            Join our community
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.bodyText}>
            We have an active group where we discuss everything from
            communication, jealousy, to sex. Polyamory is a rich world which
            covers so many different topics. Once in the community, you'll be
            able to setup your own video chat events on whatever topic interests
            you, that others can then choose to attend.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.bodyText}>
            To join, we require you to fill out a form. Once received, we will
            email you back. There is a one-time non-refundable joining fee of
            US$11 per person.
          </Typography>
          <Button
            color="primary"
            size="large"
            startIcon={<LinkIcon />}
            href="https://forms.gle/1puxvuLvNm19fgPP6"
          >
            Application Form
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.agreementsContainer}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={9} sm={8} lg={6}>
          <Typography variant="h3" gutterBottom>
            Community Agreements
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.bodyText}>
            We at Polyamory World wish to allow our members to speak freely from
            the heart in a drama free environment. To encourage this, we have
            adopted{' '}
            <Link href="https://www.amazon.com/Four-Agreements-Practical-Personal-Freedom/dp/1878424319">
              The Four Agreements
            </Link>{' '}
            as written about by Don Miguel Ruiz as our Code of Conduct.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.bodyText}>
            <ol>
              <li>
                Being impeccable with your word goes beyond just being honest
                and keeping your word. It means, as Ruiz says, your word is a
                two-edged sword. It can create or destroy. Let your word be one
                that creates.
              </li>
              <li>
                The second agreement is to not take things personally. What
                others say is a reflection of them, not you.
              </li>
              <li>Don’t make assumptions.</li>
              <li>Do your best.</li>
            </ol>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.bodyText}>
            Keeping this space welcoming is our top priority. Should any drama
            arise, our administration team will promptly convene and remove
            anyone unable to adhere to the Code of Conduct. Finally, just be
            yourselves and have fun!
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.container, classes.contactContainer)}
        justify="center"
        alignItems="center"
      >
        <Grid item className={classes.verticallyAlign} xs={8}>
          <Typography variant="h4" gutterBottom>
            Any questions?
          </Typography>
          <Typography variant="h3" gutterBottom className={classes.bodyText}>
            <Link href="mailto:polyamoryworld@pm.me">polyamoryworld@pm.me</Link>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}
